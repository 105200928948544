import React from "react";

const personalInfoContent = [
  { meta: "Vornamen", metaInfo: "Noah Gabrien" },
  { meta: "Nachname", metaInfo: "Nowak" },
  { meta: "Geburtsdatum", metaInfo: "12. August 1994" },
  { meta: "Geburtsort", metaInfo: "Salzgitter" },
  { meta: "Wohnort", metaInfo: "Vechta" },
  { meta: "Familienstand", metaInfo: "ledig" },
  { meta: "Nationalität", metaInfo: "Deutsch" },
  { meta: "Sprachen", metaInfo: "Deutsch, Englisch" },
  { meta: "Führerschein", metaInfo: "Klasse B" },
  { meta: "E-Mail", metaInfo: "kontakt@noahnowak.de" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
