import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Hero from "../../components/hero/Hero";
import Index from "../../components/about/index";
import Address from "../../components/Address";
import Imprint from "../../components/Imprint";
import Privacy from "../../components/Privacy";
import Social from "../../components/Social";

const menuItem = [
  { icon: "fa-home", menuName: "Start" },
  { icon: "fa-user", menuName: "Lebenslauf" },
  { icon: "fa-map-marker", menuName: "Kontakt" },
  { icon: "fa-info", menuName: "Impressum" },
  { icon: "fa-institution", menuName: "Datenschutz" },
];

const HomeDark = () => {
  return (
    <div className="yellow">
      <Tabs>
        <div className="header">
          <TabList className=" icon-menu  revealator-slideup revealator-once revealator-delay1">
            {menuItem.map((item, i) => (
              <Tab className="icon-box" key={i}>
                <i className={`fa ${item.icon}`}></i>
                <h2>{item.menuName}</h2>
              </Tab>
            ))}
          </TabList>
        </div>
        {/* End Menu Content */}

        <div className="tab-panel_list">
          {/* Hero Content Starts */}
          <TabPanel className="home ">
            <div
              className="container-fluid main-container container-home p-0 "
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="color-block d-none d-lg-block"></div>
              <Hero />
            </div>
          </TabPanel>
          {/* Hero Content Ends */}

          {/* About Content Starts */}
          <TabPanel className="about">
            <div data-aos="fade-up" data-aos-duration="1200">
              <div className="title-section text-left text-sm-center">
                <h1>
                  Mehr über <span>mich</span>
                </h1>
                <span className="title-bg">Lebenslauf</span>
              </div>
              {/* End title */}
              <Index />
            </div>
          </TabPanel>
          {/* About Content Ends */}

          {/* Contact Content Starts */}
          <TabPanel className="contact">
            <div
              className="title-section text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                Ihr Weg zu <span>mir</span>
              </h1>
              <span className="title-bg">Kontakt</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="row">
                {/*  Left Side Starts */}
                <div className="col-12 col-lg-4">
                  <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
                    Neugierig?
                  </h3>
                  <p className="open-sans-font mb-4">
                  Ich bin jederzeit zu einem offenen Austausch bereit. Nehmen Sie gerne Kontakt mit mir auf.
                  </p>
                  <Address />
                  {/* End Address */}

                  <Social />
                  {/* End Social */}
                </div>
                {/* Left Side Ends */}

                <div className="col-12 col-lg-8">
                  <iframe
                    className="googlemap"
                    title="Karte"
                    referrerpolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCFKTwG0FbaeiauPbfytMZHpAL68tkrOT4&q=26122+Oldenburg&zoom=8"
                    >
                  </iframe>
                </div>
              </div>
            </div>
            {/* End .container */}
          </TabPanel>
          {/* Contact Content Ends */}

          {/* Imprint Content Starts */}
          <TabPanel className="imprint">
            <div data-aos="fade-up" data-aos-duration="1200">
              <div className="title-section text-left text-sm-center">
                <h1>
                  <span>Impressum</span>
                </h1>
                <span className="title-bg">Rechtliches</span>
              </div>
              {/* End title */}
              <Imprint />
            </div>
          </TabPanel>
          {/* Imprint Content Ends */}

          {/* Privacy Content Starts */}
          <TabPanel className="privacy">
            <div data-aos="fade-up" data-aos-duration="1200">
              <div className="title-section text-left text-sm-center">
                <h1>
                  <span>Datenschutz</span>
                </h1>
                <span className="title-bg">Rechtliches</span>
              </div>
              {/* End title */}
              <Privacy />
            </div>
          </TabPanel>
          {/* Privacy Content Ends */}

        </div>
      </Tabs>
    </div>
  );
};

export default HomeDark;
